import React, { useState } from 'react';
import SubPopup from '../ProductSelector/SubPopup';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
`;

const Button = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export default function SubPopupText(props) {
  const { text, faqURL } = props;
  const [showSubPopup, setShowSubPopup] = useState(false);

  const textSplit = text.split('|');
  const text1 = textSplit[0] || '';
  const text2 = textSplit[1] || '';
  const text3 = textSplit[2] || '';

  return (
    <Container>
      {text1}
      <Button onClick={() => setShowSubPopup(true)} className="trigger">
        {text2}
      </Button>
      {text3}
      <SubPopup
        show={showSubPopup}
        setShowSubPopup={setShowSubPopup}
        faqURL={faqURL}
      />
    </Container>
  );
}
