import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  max-width: 320px;
  min-height: 250px;
  z-index: 9999;
  top: calc(50% - 150px);
  left: calc(50% - 200px);
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 2px;
  padding: 25px 25px 25px 55px;
  &:before {
    content: ' ';
    background-image: url(/images/paws.svg);
    width: 31px;
    height: 42px;
    display: block;
    position: absolute;
    margin-left: -31px;
  }
  @media (max-width: 425px) {
    max-width: 210px;
    left: calc(50% - 155px);
    min-height: 270px;
    padding: 25px 45px 25px 55px;
  }
  @media (max-width: 320px) {
    max-width: 160px;
    left: calc(50% - 130px);
    min-height: 300px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                &:before {
                  background-image: url(/images/paws2.svg);
                }
                `;
      default:
        return `
                   
                `;
    }
  }}
`;

export const Body = styled.div`
  padding-left: 10px;
  border-left: 1px solid #e26447;
  font-size: 14px;
  position: relative;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                        & a {
                            color: #BC2E3E!important;
                        }
                        border-left: 1px solid #BC2E3E;
                    `;
      default:
        return `
                    & a {
                        color: #e26447!important;
                    }
                    `;
    }
  }}
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  top: -15px;
  cursor: pointer;
  font-size: 30px;
  font-family: Neuzeit Grotesk;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    background-color:#BC2E3E;
                `;
      default:
        return `
                    background-color:#E26447;
                `;
    }
  }}

  & > span {
    display: block;
    margin: auto;
  }
`;

export const CartX = styled.img``;
